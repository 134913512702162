import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import TileLayerFromProps from "../tile_layer_from_props";

export default (): JSX.Element => {
  const mapType = useSelector((state: SystemState) => state.projectTree.mapState.mapTypeId);
  const showingMapLabels = useSelector(
    (state: SystemState) => state.projectTree.mapState.showingMapLabels
  );

  return <TileLayerFromProps mapType={mapType} showingMapLabels={showingMapLabels} />;
};
