import {
  Area,
  BoundingBox,
  CompletePlannedArea,
  CpuLog,
  Flight,
  Location,
  ReleaserConfiguration,
  Waypoint,
} from "biohub-model";
import _ from "lodash";
import { BaseMapTypeId } from "../../components/map/BaseMap";
import AreaInProjectTree from "../../components/ProjectTree/components/AreaInProjectTree";
import { polygonAvailableAreaForPlanning } from "../../core/areaPlanning";
import {
  boundingBoxDiagonalSize,
  boundingBoxForAreas,
  boundingBoxForLocations,
  calculateAreaHa,
} from "../../core/geometricFunctions";
import { ProjectWithAreaCount } from "../../services/ProjectService";
import { BiohubError } from "../../services/axios/BiohubApi";
import {
  AreaConfigWithoutId,
  COLLAPSE_AREA,
  COLLAPSE_PROJECT,
  CreteProjectInfo,
  DELETE_AREA,
  DELETE_PROJECT,
  ENTER_EDIT_AREA_MODE,
  EXPAND_AREA,
  EXPAND_PROJECT,
  INCREASE_PROJECTS_WITH_LOADED_AREAS_COUNT,
  PUT_PROJECT,
  PUT_PROJECT_AREA,
  QUIT_EDIT_AREA_MODE,
  QUIT_VIEW_FLIGHT_PLAN,
  RESET_PROJECT_WITH_LOADED_AREAS_COUNT,
  SELECT_AREA,
  SELECT_PROJECT,
  SELECT_VIEW_FLIGHT_PLAN,
  SET_AREA_FLIGHT_LIST,
  SET_AREA_LOADING_FLIGHTS,
  SET_AREA_VISIBILITY,
  SET_COPIED_AREA,
  SET_CREATING_AREA,
  SET_CREATING_PROJECT,
  SET_DELETING_AREA,
  SET_DELETING_PROJECT,
  SET_FLIGHT_VISIBILITY,
  SET_LOADING_PROJECTS,
  SET_MAP_INITIALIZED,
  SET_MAP_STATE,
  SET_MAP_TYPE,
  SET_PROJECTS_AREA_LIST,
  SET_PROJECTS_LOADING_AREAS,
  SET_PROJECT_AREA_LIST,
  SET_PROJECT_LIST,
  SET_PROJECT_LOADING_AREAS,
  SET_PROJECT_TREE_ERROR,
  SET_PROJECT_VISIBILITY,
  SET_RUNNING_MISSION_PLANNER,
  SET_UPDATING_AREA,
  SET_UPDATING_PROJECT,
  SET_USER_LOCATION,
  UPDATE_DIAGONAL_SCREEN_SIZE,
  UPDATE_EDIT_AREA_DATA,
  SET_ITEMS_VISIBILITY,
  SET_MOVING_MAP_CAMERA,
  SET_AREA_BEING_EXPORTED_AS_KML,
  SET_FLIGHT_BEING_EXPORTED_AS_KML,
  SET_PROJECT_FLIGHT_LIST,
  SET_PROJECT_LOADING_FLIGHTS,
  SET_LOADING_CPU_LOGS,
  SET_CPU_LOGS_LIST,
  EXPAND_CPUS_LOGS,
  COLLAPSE_CPU_LOGS,
  SET_SHOWING_MAP_LABELS,
  FETCH_MAP_PIXELS_DIMENSIONS,
  SET_SELECTED_WAYPOINT_INDEXES,
  SET_PRESSING_CTRL_KEY,
  SET_VISUALIZATION_MODE,
  SET_EXPANDED_PROJECTS,
  FETCH_PROJECT_TREE_SCROLL_POSITION,
  UPDATE_PROJECT,
  UPDATE_PROJECT_AREA,
} from "../actions/projectTreeActions";
import { SystemAction } from "../actions/systemActions";

export type VisualizationMode = "not_deleted" | "deleted" | "all";

export type ProjectTreeMapState = {
  bounds: BoundingBox;
  boundingBoxCenter: Location;
  boundingBoxDiagonalSize: number;
  center: Location;
  zoom: number;
  mapTypeId: BaseMapTypeId;
  showingMapLabels: boolean;
  visibleRegionDiagonalInPixels: number;
  draggingMarker: boolean;
  rotation: number;
};

export type ProjectTreeState = {
  isMapInitialized: boolean;
  isLoadingProjectList: boolean;
  projectsError: BiohubError | null;
  mapState: ProjectTreeMapState;
  userLocation: Location | undefined | null;
  selectedProjectId: string | null;
  projectList: ProjectInProjectTree[] | undefined | null;
  amountOfProjectsWithLoadedAreas: number;
  isCreatingProject: boolean;
  creatingProjectsIds: string[];
  editingArea: EditingArea | undefined;
  copiedArea: AreaInProjectTree | undefined;
  diagonalScreenSize: number | undefined;
  itemsVisible: boolean;
  movingCamera: boolean;
  isLoadingCpuLogsList: boolean;
  cpuLogsList: ProjectTreeExpandableItem<{
    cpuLogs: CpuLogInProjectTree[];
  }>;
  selectedWaypoints:
    | {
        projectId: string;
        areaId: string;
        waypointIndexes: number[];
      }
    | undefined;
  pressingCtrlKey: boolean;
  visualizationMode: VisualizationMode;
  visualizationModeConsideringSubItems: boolean;
  isExpandedProjects: boolean;
  scrollPosition: number;
};

export type CpuLogInProjectTree = ProjectTreeItem<CpuLog>;

export type EditingArea =
  | {
      type: EditingAreaType.CreatingProject;
      creatingProject: CreteProjectInfo;
      areaName: string;
      polygon: Location[];
    }
  | {
      type: EditingAreaType.DrawingNewArea;
      projectId: string;
      areaName: string;
      areaConfig: AreaConfigWithoutId;
      configuredReleasers: ReleaserConfiguration[];
      polygon: Location[];
    }
  | {
      type: EditingAreaType.EditingPolygon;
      area: Area;
      polygon: Location[];
    }
  | {
      type: EditingAreaType.EditingPlanPoints;
      area: Area;
      homePoint: Location | undefined;
      waypoints: Waypoint[];
    }
  | {
      type: EditingAreaType.EditingEverything;
      area: Area;
      homePoint: Location | undefined;
      polygon: Location[];
      waypoints: Waypoint[];
    }
  | {
      type: EditingAreaType.MissionPlannerRotation;
      area: Area;
      basePoint: Location | undefined;
      routeAngle: number;
      waypoints: Waypoint[];
      homePoint: Location | undefined;
    };

export enum EditingAreaType {
  CreatingProject,
  EditingPolygon,
  EditingPlanPoints,
  EditingEverything,
  DrawingNewArea,
  MissionPlannerRotation,
}

export type ProjectInProjectTree = ProjectTreeExpandableItem<
  Omit<ProjectWithAreaCount, "boundingBox" | "deletedAt"> & {
    deletedAt: Date | null;
    isLoadingAreas: boolean;
    isUpdating: boolean;
    selectedAreaId: string | null;
    areas: AreaInProjectTree[] | null;
    isCreatingArea: boolean;
    creatingAreaIds: string[];
    beingDeleted: boolean;
    boundingBox: BoundingBox | null;
    boundingBoxDiagonalSize: number;
  }
>;

export type AreaInProjectTree = ProjectTreeExpandableItem<
  Omit<Area, "deletedAt"> & {
    deletedAt: Date | null;
    isLoadingFlights: boolean;
    isUpdating: boolean;
    isRunningMissionPlanner: boolean;
    viewingFlightPlan:
      | (CompletePlannedArea & {
          boundingBox: BoundingBox;
          boundingBoxDiagonalSize: number;
        })
      | null;
    flightList: FlightInProjectTree[] | null;
    beingDeleted: boolean;
    areaSizeInHa: number;
    areaAvailableSizeToPlanInHa: number;
    boundingBox: BoundingBox;
    boundingBoxDiagonalSize: number;
    isExportingAsKml: boolean;
  }
>;

export type FlightInProjectTree = ProjectTreeItem<Flight & { isExportingAsKml: boolean }>;

type ProjectTreeItem<T> = T & {
  visible: boolean;
};

type ProjectTreeExpandableItem<T> = ProjectTreeItem<T> & {
  expanded: boolean;
};

const initialProjectTreeBoundingBox = {
  south: -90,
  north: 90,
  west: -180,
  east: 180,
};

export const initialProjectTreeMapState: ProjectTreeMapState = {
  bounds: initialProjectTreeBoundingBox,
  boundingBoxDiagonalSize: boundingBoxDiagonalSize(initialProjectTreeBoundingBox),
  boundingBoxCenter: {
    lat: 0,
    lng: 0,
  },
  center: {
    lat: -23.156908,
    lng: -45.790692,
  },
  mapTypeId: "satellite",
  showingMapLabels: true,
  zoom: 5,
  visibleRegionDiagonalInPixels: 0,
  draggingMarker: false,
  rotation: 0,
};

const INITIAL_STATE: ProjectTreeState = {
  isMapInitialized: false,
  isLoadingProjectList: false,
  mapState: initialProjectTreeMapState,
  selectedProjectId: null,
  projectList: undefined,
  amountOfProjectsWithLoadedAreas: 0,
  projectsError: null,
  userLocation: undefined,
  isCreatingProject: false,
  creatingProjectsIds: [],
  editingArea: undefined,
  copiedArea: undefined,
  diagonalScreenSize: undefined,
  itemsVisible: true,
  movingCamera: false,
  isLoadingCpuLogsList: false,
  cpuLogsList: {
    visible: false,
    expanded: false,
    cpuLogs: [],
  },
  selectedWaypoints: undefined,
  pressingCtrlKey: false,
  visualizationMode: "not_deleted",
  visualizationModeConsideringSubItems: true,
  isExpandedProjects: false,
  scrollPosition: 0,
};

export function projectTreeReducer(state = INITIAL_STATE, action: SystemAction): ProjectTreeState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  let projectList: ProjectInProjectTree[] | undefined | null = effectiveState.projectList;
  let creatingProjectsIds = effectiveState.creatingProjectsIds;
  let editingArea: EditingArea | undefined = effectiveState.editingArea;
  let boundingBoxProject: BoundingBox;
  let projectAreas: AreaInProjectTree | null;

  switch (action.type) {
    case SET_MAP_INITIALIZED:
      return {
        ...effectiveState,
        isMapInitialized: true,
      };
    case SET_LOADING_PROJECTS:
      return {
        ...effectiveState,
        isLoadingProjectList: action.payload.loading,
      };
    case SET_PROJECT_LIST: {
      projectList = getNewProjectListUsingPreviousStateParameters(
        effectiveState.projectList,
        action.payload.projects
      );

      return {
        ...effectiveState,
        projectList: projectList,
      };
    }
    case PUT_PROJECT:
      if (projectList === undefined || projectList === null) {
        projectList = [];
      }
      projectList.push(defaultProjectInProjectTree(action.payload.project));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case UPDATE_PROJECT:
      projectList = projectList?.map((project) =>
        project.id === action.payload.project.id
          ? {
              ...project,
              ...action.payload.project,
              deletedAt: Object.keys(action.payload.project).includes("deletedAt")
                ? action.payload.project.deletedAt ?? null
                : project.deletedAt,
            }
          : project
      );

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case DELETE_PROJECT:
      projectList = projectList?.filter((project) => project.id !== action.payload.projectId);

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_PROJECT_TREE_ERROR:
      return {
        ...effectiveState,
        projectsError: action.payload.error,
      };
    case SET_ITEMS_VISIBILITY:
      projectList =
        projectList === null || projectList === undefined
          ? projectList
          : projectList.map((project) => ({
              ...project,
              visible: action.payload.visibility,
              areas:
                project.areas === null
                  ? null
                  : project.areas.map((area) => ({
                      ...area,
                      visible: action.payload.visibility,
                      flightList:
                        !action.payload.visibility && area.flightList !== null
                          ? area.flightList.map((flight) => ({
                              ...flight,
                              visible: false,
                            }))
                          : area.flightList,
                    })),
            }));

      return {
        ...effectiveState,
        projectList: projectList,
        itemsVisible: action.payload.visibility,
      };
    case SET_PROJECT_VISIBILITY:
      projectList =
        projectList === null || projectList === undefined
          ? projectList
          : projectList.map((project) => {
              if (project.id !== action.payload.projectId) return project;

              const areas =
                project.areas === null
                  ? null
                  : project.areas.map((area) => ({
                      ...area,
                      visible: action.payload.visibility,
                      flightList:
                        !action.payload.visibility && area.flightList !== null
                          ? area.flightList.map((flight) => ({
                              ...flight,
                              visible: false,
                            }))
                          : area.flightList,
                    }));

              return {
                ...project,
                areas: areas,
                visible: action.payload.visibility,
              };
            });

      return {
        ...effectiveState,
        projectList: projectList,
        itemsVisible:
          projectList === null || projectList === undefined
            ? effectiveState.itemsVisible
            : safeBooleanListReducer(
                projectList.map((project) => project.visible),
                (a, b) => a || b,
                false
              ),
      };
    case SET_AREA_VISIBILITY:
      projectList =
        projectList === null || projectList === undefined
          ? projectList
          : projectList.map((project) => {
              if (project.id !== action.payload.projectId) return project;

              const areas =
                project.areas === null
                  ? null
                  : project.areas.map((area) => {
                      if (area.id !== action.payload.areaId) return area;

                      return {
                        ...area,
                        visible: action.payload.visibility,
                        flightList:
                          area.flightList !== null && !action.payload.visibility
                            ? area.flightList.map((flight) => ({
                                ...flight,
                                visible: false,
                              }))
                            : area.flightList,
                      };
                    });

              return {
                ...project,
                areas: areas,
                visible:
                  areas === null
                    ? project.visible
                    : safeBooleanListReducer(
                        areas.map((area) => area.visible),
                        (a, b) => a || b,
                        false
                      ),
              };
            });

      return {
        ...effectiveState,
        projectList: projectList,
        itemsVisible:
          projectList === null || projectList === undefined
            ? effectiveState.itemsVisible
            : safeBooleanListReducer(
                projectList.map((project) => project.visible),
                (a, b) => a || b,
                false
              ),
      };
    case SET_FLIGHT_VISIBILITY:
      projectList =
        projectList === null || projectList === undefined
          ? projectList
          : projectList.map((project) => {
              if (project.id !== action.payload.projectId) return project;

              const areas =
                project.areas === null
                  ? null
                  : project.areas.map((area) => {
                      if (area.id !== action.payload.areaId) return area;

                      const flightList =
                        area.flightList === null
                          ? null
                          : area.flightList.map((flight) => {
                              if (flight.id !== action.payload.flightId) return flight;

                              return {
                                ...flight,
                                visible: action.payload.visibility,
                              };
                            });

                      return {
                        ...area,
                        flightList: flightList,
                      };
                    });

              return {
                ...project,
                areas: areas,
              };
            });

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SELECT_PROJECT:
      return {
        ...effectiveState,
        selectedProjectId: action.payload.projectId,
      };
    case EXPAND_PROJECT:
      projectList = projectList?.map((project) => ({
        ...project,
        expanded: project.id === action.payload.projectId ? true : project.expanded,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case COLLAPSE_PROJECT:
      projectList = projectList?.map((project) => ({
        ...project,
        expanded: project.id === action.payload.projectId ? false : project.expanded,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SELECT_AREA:
      projectList = projectList?.map((project) => ({
        ...project,
        selectedAreaId: action.payload.areaId,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case EXPAND_AREA:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas?.map((area) => ({
                ...area,
                expanded: area.id === action.payload.areaId ? true : area.expanded,
              }))
            : project.areas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case COLLAPSE_AREA:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas?.map((area) => ({
                ...area,
                expanded: area.id === action.payload.areaId ? false : area.expanded,
              }))
            : project.areas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_PROJECT_LOADING_AREAS:
      projectList = projectList?.map((project) => ({
        ...project,
        isLoadingAreas:
          project.id === action.payload.projectId ? action.payload.loading : project.isLoadingAreas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };

    case SET_PROJECTS_LOADING_AREAS:
      projectList = projectList?.map((project) => ({
        ...project,
        isLoadingAreas: action.payload.projectIds.includes(project.id)
          ? action.payload.loading
          : project.isLoadingAreas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };

    case SET_PROJECT_AREA_LIST:
      projectList = projectList?.map((project) => {
        const projectAreas =
          project.id === action.payload.projectId
            ? getNewAreaListUsingPreviousStateParameters(
                project.areas,
                action.payload.areas,
                effectiveState.itemsVisible
              )
            : project.areas;
        const projectAreaBoundingBox =
          projectAreas !== null
            ? boundingBoxForLocations(
                projectAreas
                  .map((area): Location[] => [
                    { lat: area.boundingBox.north, lng: area.boundingBox.east },
                    { lat: area.boundingBox.south, lng: area.boundingBox.west },
                  ])
                  .flat()
              )
            : null;

        return {
          ...project,
          areaCount:
            project.id === action.payload.projectId
              ? action.payload.areas.length
              : project.areaCount,
          areas: projectAreas,

          boundingBox: projectAreaBoundingBox,
          boundingBoxDiagonalSize:
            projectAreaBoundingBox !== null ? boundingBoxDiagonalSize(projectAreaBoundingBox) : 0,
          visible:
            projectAreas !== null &&
            safeBooleanListReducer(
              projectAreas.map((area) => area.visible),
              (a, b) => a || b,
              false
            ),
        };
      });

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_PROJECTS_AREA_LIST:
      projectList = projectList?.map((project) => {
        const payloadProject = action.payload.projects.find(
          (payloadProject: { projectId: string; areas: Area[] }) =>
            payloadProject.projectId === project.id
        );
        const projectAreas =
          payloadProject !== undefined
            ? getNewAreaListUsingPreviousStateParameters(
                project.areas,
                payloadProject.areas,
                effectiveState.itemsVisible
              )
            : project.areas;
        const projectAreaBoundingBox =
          projectAreas !== null
            ? boundingBoxForLocations(
                projectAreas
                  .map((area): Location[] => [
                    { lat: area.boundingBox.north, lng: area.boundingBox.east },
                    { lat: area.boundingBox.south, lng: area.boundingBox.west },
                  ])
                  .flat()
              )
            : null;

        return {
          ...project,
          areaCount: payloadProject !== undefined ? payloadProject.areas.length : project.areaCount,
          areas: projectAreas,

          boundingBox: projectAreaBoundingBox,
          boundingBoxDiagonalSize:
            projectAreaBoundingBox !== null ? boundingBoxDiagonalSize(projectAreaBoundingBox) : 0,
          visible:
            projectAreas !== null &&
            safeBooleanListReducer(
              projectAreas.map((area) => area.visible),
              (a, b) => a || b,
              false
            ),
        };
      });

      return {
        ...effectiveState,
        projectList: projectList,
      };

    case PUT_PROJECT_AREA:
      projectList = projectList?.map((project) => {
        const projectAreas = ((): AreaInProjectTree[] | null => {
          if (project.id === action.payload.projectId) {
            let newAreaList = project.areas;

            if (newAreaList === null) {
              newAreaList = [];
            }

            newAreaList.push(
              defaultAreaInProjectTree(action.payload.area, effectiveState.itemsVisible)
            );
          }

          return project.areas;
        })();
        const projectAreaBoundingBox =
          projectAreas !== null
            ? boundingBoxForLocations(
                projectAreas
                  .map((area): Location[] => [
                    { lat: area.boundingBox.north, lng: area.boundingBox.east },
                    { lat: area.boundingBox.south, lng: area.boundingBox.west },
                  ])
                  .flat()
              )
            : null;

        return {
          ...project,
          areas: projectAreas,
          boundingBox: projectAreaBoundingBox,
          boundingBoxDiagonalSize:
            projectAreaBoundingBox !== null ? boundingBoxDiagonalSize(projectAreaBoundingBox) : 0,
        };
      });

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case UPDATE_PROJECT_AREA:
      projectList = projectList?.map((project) => {
        if (project.id !== action.payload.projectId || project.areas === null) {
          return project;
        }

        return {
          ...project,
          areas: project.areas.map((area) => {
            if (area.id !== action.payload.area.id) {
              return area;
            }

            return {
              ...area,
              ...action.payload.area,
              deletedAt: Object.keys(action.payload.area).includes("deletedAt")
                ? action.payload.area.deletedAt ?? null
                : area.deletedAt,
            };
          }),
        };
      });

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case DELETE_AREA:
      projectList = projectList?.map((project) => {
        const projectAreas =
          project.id === action.payload.projectId
            ? project.areas?.filter((area) => area.id !== action.payload.areaId) ?? null
            : project.areas;
        const projectAreaBoundingBox =
          projectAreas !== null
            ? boundingBoxForLocations(
                projectAreas
                  .map((area): Location[] => [
                    { lat: area.boundingBox.north, lng: area.boundingBox.east },
                    { lat: area.boundingBox.south, lng: area.boundingBox.west },
                  ])
                  .flat()
              )
            : null;

        return {
          ...project,
          areas: projectAreas,
          boundingBox: projectAreaBoundingBox,
          boundingBoxDiagonalSize:
            projectAreaBoundingBox !== null ? boundingBoxDiagonalSize(projectAreaBoundingBox) : 0,
        };
      });

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_AREA_LOADING_FLIGHTS:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas?.map((area) => ({
                ...area,
                isLoadingFlights:
                  area.id === action.payload.areaId
                    ? action.payload.loading
                    : area.isLoadingFlights,
              }))
            : project.areas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_PROJECT_LOADING_FLIGHTS:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas?.map((area) => ({
                ...area,
                isLoadingFlights: action.payload.loading,
              }))
            : project.areas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_AREA_FLIGHT_LIST:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas.map((area) => ({
                ...area,
                flightList:
                  area.id === action.payload.areaId
                    ? getNewFlightListUsingPreviousStateParameters(
                        area.flightList,
                        action.payload.flights
                      )
                    : area.flightList,
              }))
            : project.areas,
      }));
      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_PROJECT_FLIGHT_LIST:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas.map((area) => ({
                ...area,
                flightList: getNewFlightListUsingPreviousStateParameters(
                  area.flightList,
                  action.payload.flights.filter(
                    (flight) => flight.flightEnvironmentSnapshot.areaId === area.id
                  )
                ),
              }))
            : project.areas,
      }));
      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_CREATING_PROJECT:
      const projectId = action.payload.projectId;
      if (action.payload.creating) {
        if (!creatingProjectsIds.includes(projectId)) {
          creatingProjectsIds.push(projectId);
        }
      } else {
        const indexOf = creatingProjectsIds.indexOf(projectId);
        if (indexOf >= 0) {
          creatingProjectsIds.splice(indexOf, 1);
        }
      }
      return {
        ...effectiveState,
        isCreatingProject: creatingProjectsIds.length !== 0,
        creatingProjectsIds: creatingProjectsIds,
      };
    case SET_CREATING_AREA:
      projectList = projectList?.map((project) => {
        if (project.id !== action.payload.projectId) {
          return project;
        }

        const creatingAreaIds = project.creatingAreaIds;

        const areaId = action.payload.areaId;
        if (action.payload.creating) {
          if (!creatingAreaIds.includes(areaId)) {
            creatingAreaIds.push(areaId);
          }
        } else {
          const indexOf = creatingAreaIds.indexOf(areaId);
          if (indexOf >= 0) {
            creatingAreaIds.splice(indexOf, 1);
          }
        }
        return {
          ...project,
          isCreatingArea: creatingAreaIds.length !== 0,
          creatingAreaIds: creatingAreaIds,
        };
      });

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_UPDATING_PROJECT:
      projectList = projectList?.map((project) => ({
        ...project,
        isUpdating:
          project.id === action.payload.projectId ? action.payload.updating : project.isUpdating,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_UPDATING_AREA:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas.map((area) => ({
                ...area,
                isUpdating:
                  area.id === action.payload.areaId ? action.payload.updating : area.isUpdating,
              }))
            : project.areas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_RUNNING_MISSION_PLANNER:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas.map((area) => ({
                ...area,
                isRunningMissionPlanner:
                  area.id === action.payload.areaId
                    ? action.payload.planning
                    : area.isRunningMissionPlanner,
              }))
            : project.areas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_USER_LOCATION:
      return {
        ...effectiveState,
        userLocation: action.payload.userLocation,
      };
    case SET_DELETING_PROJECT:
      projectList = projectList?.map((project) => ({
        ...project,
        beingDeleted:
          project.id === action.payload.projectId ? action.payload.deleting : project.beingDeleted,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_DELETING_AREA:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas.map((area) => ({
                ...area,
                beingDeleted:
                  area.id === action.payload.areaId ? action.payload.deleting : area.beingDeleted,
              }))
            : project.areas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SELECT_VIEW_FLIGHT_PLAN:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas.map((area): AreaInProjectTree => {
                if (action.payload.areaId === area.id) {
                  const viewingFlightPlan = action.payload.plan;
                  const boundingBox =
                    boundingBoxForAreas([viewingFlightPlan.polygon]) ?? area.boundingBox;

                  return {
                    ...area,
                    viewingFlightPlan: {
                      ...viewingFlightPlan,
                      boundingBox: boundingBox,
                      boundingBoxDiagonalSize: boundingBoxDiagonalSize(boundingBox),
                    },
                  };
                }

                return area;
              })
            : project.areas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case QUIT_VIEW_FLIGHT_PLAN:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas.map((area) => ({
                ...area,
                viewingFlightPlan:
                  area.id === action.payload.areaId ? null : area.viewingFlightPlan,
              }))
            : project.areas,
      }));

      return {
        ...effectiveState,
        projectList: projectList,
      };
    case ENTER_EDIT_AREA_MODE:
      if (action.payload.type === "create-project") {
        editingArea = {
          type: EditingAreaType.CreatingProject,
          creatingProject: action.payload.projectInfo,
          areaName: action.payload.areaName,
          polygon: [],
        };
      }
      if (action.payload.type === "create-area") {
        editingArea = {
          type: EditingAreaType.DrawingNewArea,
          projectId: action.payload.projectId,
          areaName: action.payload.areaName,
          areaConfig: action.payload.areaConfig,
          configuredReleasers: action.payload.configuredReleasers,
          polygon: [],
        };
      }
      if (action.payload.type === "edit-points") {
        editingArea = {
          type: EditingAreaType.EditingEverything,
          area: action.payload.area,
          homePoint: action.payload.area.planned.homePoint,
          polygon: action.payload.area.planned.polygon,
          waypoints: action.payload.area.planned.route?.waypoints ?? [],
        };
      }
      if (action.payload.type === "edit-route") {
        editingArea = {
          type: EditingAreaType.EditingPlanPoints,
          area: action.payload.area,
          homePoint: action.payload.area.planned.homePoint,
          waypoints: action.payload.area.planned.route?.waypoints ?? [],
        };
      }
      if (action.payload.type === "manual-route") {
        editingArea = {
          type: EditingAreaType.EditingPlanPoints,
          area: action.payload.area,
          homePoint: undefined,
          waypoints: [],
        };
      }
      if (action.payload.type === "edit-polygon") {
        editingArea = {
          type: EditingAreaType.EditingPolygon,
          area: action.payload.area,
          polygon: action.payload.area.planned.polygon,
        };
      }
      if (action.payload.type === "rotate-plan") {
        editingArea = {
          type: EditingAreaType.MissionPlannerRotation,
          area: action.payload.area,
          basePoint: action.payload.basePoint,
          routeAngle: action.payload.routeAngle,
          waypoints: action.payload.waypoints,
          homePoint: action.payload.homePoint,
        };
      }

      if (editingArea !== undefined) {
        editingArea = _.cloneDeep(editingArea);
      }

      return {
        ...effectiveState,
        editingArea: editingArea,
      };
    case UPDATE_EDIT_AREA_DATA:
      return {
        ...effectiveState,
        editingArea: action.payload.editingArea,
      };
    case QUIT_EDIT_AREA_MODE:
      return {
        ...effectiveState,
        editingArea: undefined,
      };
    case SET_MAP_TYPE:
      return {
        ...effectiveState,
        mapState: {
          ...effectiveState.mapState,
          mapTypeId: action.payload.mapType,
        },
      };
    case SET_MAP_STATE:
      let newMapState: ProjectTreeMapState = { ...effectiveState.mapState };

      for (const [key, value] of Object.entries(action.payload)) {
        if (value !== undefined) {
          newMapState = {
            ...newMapState,
            [key]: value,
          };
        }
      }

      return {
        ...effectiveState,
        mapState: newMapState,
      };
    case SET_COPIED_AREA:
      return {
        ...effectiveState,
        copiedArea: action.payload.copiedArea,
      };

    case UPDATE_DIAGONAL_SCREEN_SIZE:
      return {
        ...effectiveState,
        diagonalScreenSize: action.payload.diagonalScreenSize,
      };
    case RESET_PROJECT_WITH_LOADED_AREAS_COUNT:
      return {
        ...effectiveState,
        amountOfProjectsWithLoadedAreas: 0,
      };
    case INCREASE_PROJECTS_WITH_LOADED_AREAS_COUNT:
      return {
        ...effectiveState,
        amountOfProjectsWithLoadedAreas:
          effectiveState.amountOfProjectsWithLoadedAreas + action.payload.amount,
      };
    case SET_MOVING_MAP_CAMERA:
      return {
        ...effectiveState,
        movingCamera: action.payload.moving,
      };
    case SET_AREA_BEING_EXPORTED_AS_KML:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas?.map((area) => ({
                ...area,
                isExportingAsKml:
                  area.id === action.payload.areaId
                    ? action.payload.loading
                    : area.isLoadingFlights,
              }))
            : project.areas,
      }));
      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_FLIGHT_BEING_EXPORTED_AS_KML:
      projectList = projectList?.map((project) => ({
        ...project,
        areas:
          project.id === action.payload.projectId && project.areas !== null
            ? project.areas?.map((area) => ({
                ...area,
                flightList:
                  area.flightList?.map((flight) => ({
                    ...flight,
                    isExportingAsKml:
                      flight.id === action.payload.flightId
                        ? action.payload.loading
                        : flight.isExportingAsKml,
                  })) || null,
              }))
            : project.areas,
      }));
      return {
        ...effectiveState,
        projectList: projectList,
      };
    case SET_LOADING_CPU_LOGS:
      return {
        ...effectiveState,
        isLoadingCpuLogsList: action.payload.loading,
      };
    case SET_CPU_LOGS_LIST:
      return {
        ...effectiveState,
        cpuLogsList: {
          ...effectiveState.cpuLogsList,
          cpuLogs: getNewCpuLogListUsingPreviousStateParameters(
            effectiveState.cpuLogsList.cpuLogs ?? null,
            action.payload.cpuLogs,
            effectiveState.cpuLogsList.visible
          ),
        },
      };
    case EXPAND_CPUS_LOGS:
      return {
        ...effectiveState,
        cpuLogsList: {
          ...effectiveState.cpuLogsList,
          expanded: true,
        },
      };
    case COLLAPSE_CPU_LOGS:
      return {
        ...effectiveState,
        cpuLogsList: {
          ...effectiveState.cpuLogsList,
          expanded: false,
        },
      };
    case SET_SHOWING_MAP_LABELS:
      return {
        ...effectiveState,
        mapState: {
          ...effectiveState.mapState,
          showingMapLabels: action.payload.showing,
        },
      };
    case FETCH_MAP_PIXELS_DIMENSIONS:
      return {
        ...effectiveState,
        mapState: {
          ...effectiveState.mapState,
          visibleRegionDiagonalInPixels: Math.sqrt(
            Math.pow(action.payload.pixelsHeight, 2) + Math.pow(action.payload.pixelsWidth, 2)
          ),
        },
      };
    case SET_SELECTED_WAYPOINT_INDEXES:
      return {
        ...effectiveState,
        selectedWaypoints: action.payload,
      };
    case SET_PRESSING_CTRL_KEY:
      return {
        ...effectiveState,
        pressingCtrlKey: action.payload.pressing,
      };
    case SET_VISUALIZATION_MODE:
      return {
        ...effectiveState,
        visualizationMode: action.payload.mode,
        visualizationModeConsideringSubItems: action.payload.consideringSubItems,
      };
    case SET_EXPANDED_PROJECTS:
      return {
        ...effectiveState,
        isExpandedProjects: action.payload.expanded,
      };
    case FETCH_PROJECT_TREE_SCROLL_POSITION:
      return {
        ...effectiveState,
        scrollPosition: action.payload.position,
      };
    default:
      return effectiveState;
  }
}

function getNewCpuLogListUsingPreviousStateParameters(
  oldCpuLogList: CpuLogInProjectTree[] | null,
  actualCpuLogList: CpuLog[],
  defaultVisibility: boolean
): CpuLogInProjectTree[] {
  return actualCpuLogList.map((cpuLog) => {
    const oldCpuLog = (oldCpuLogList ?? []).find((oldCpuLog) => oldCpuLog.id === cpuLog.id);

    return {
      ...cpuLog,
      visible: oldCpuLog?.visible ?? defaultVisibility,
    };
  });
}

function getNewAreaListUsingPreviousStateParameters(
  oldStateAreas: AreaInProjectTree[] | null,
  actualAreaList: Area[],
  defaultVisibility: boolean
): AreaInProjectTree[] {
  return actualAreaList.map((area) => {
    const oldArea = (oldStateAreas ?? []).find((oldArea) => oldArea.id === area.id);
    if (oldArea !== undefined) {
      const hasPolygonChange = !_.isEqual(oldArea.planned.polygon, area.planned.polygon);

      return {
        ...oldArea,
        ...area,
        deletedAt: area.deletedAt ?? null,
        areaSizeInHa: !_.isEqual(oldArea.planned.polygon, area.planned.polygon)
          ? calculateAreaHa(area.planned.polygon)
          : oldArea.areaSizeInHa,
        areaAvailableSizeToPlanInHa:
          hasPolygonChange ||
          oldArea.areaConfig.areaPadding !== area.areaConfig.areaPadding ||
          oldArea.areaConfig.trackWidth !== area.areaConfig.trackWidth
            ? calculateAreaHa(
                polygonAvailableAreaForPlanning(
                  area.planned.polygon,
                  area.areaConfig.areaPadding,
                  area.areaConfig.trackWidth
                )
              )
            : oldArea.areaAvailableSizeToPlanInHa,
        boundingBox: hasPolygonChange
          ? boundingBoxForAreas([area.planned.polygon])!
          : oldArea.boundingBox,
      };
    }

    return defaultAreaInProjectTree(area, defaultVisibility);
  });
}

function defaultAreaInProjectTree(area: Area, defaultVisibility: boolean): AreaInProjectTree {
  const boundingBox = boundingBoxForAreas([area.planned.polygon])!;
  return {
    ...area,
    deletedAt: area.deletedAt ?? null,
    visible: defaultVisibility,
    expanded: false,
    isLoadingFlights: false,
    isUpdating: false,
    isRunningMissionPlanner: false,
    viewingFlightPlan: null,
    flightList: null,
    beingDeleted: false,
    isExportingAsKml: false,
    areaSizeInHa: calculateAreaHa(area.planned.polygon),
    areaAvailableSizeToPlanInHa: calculateAreaHa(
      polygonAvailableAreaForPlanning(
        area.planned.polygon,
        area.areaConfig.areaPadding,
        area.areaConfig.trackWidth
      )
    ),
    boundingBox: boundingBox,
    boundingBoxDiagonalSize: boundingBoxDiagonalSize(boundingBox),
  };
}

function getNewFlightListUsingPreviousStateParameters(
  oldStateFlights: FlightInProjectTree[] | null,
  actualFlightList: Flight[]
): FlightInProjectTree[] {
  return actualFlightList.map((flight) => {
    const oldFlight = oldStateFlights?.find((oldFlight) => oldFlight.id === flight.id);

    return {
      ...flight,
      visible: oldFlight?.visible ?? false,
      isExportingAsKml: oldFlight?.isExportingAsKml ?? false,
    };
  });
}

function getNewProjectListUsingPreviousStateParameters(
  oldStateProjects: ProjectInProjectTree[] | undefined | null,
  actualProjectList: ProjectWithAreaCount[]
): ProjectInProjectTree[] {
  return actualProjectList.map((project) => {
    const oldProject = oldStateProjects?.find((oldProject) => oldProject.id === project.id);

    if (oldProject !== undefined) {
      return {
        ...oldProject,
        ...project,
        deletedAt: project.deletedAt ?? null,
      };
    }

    return defaultProjectInProjectTree(project);
  });
}

function defaultProjectInProjectTree(project: ProjectWithAreaCount): ProjectInProjectTree {
  return {
    ...project,
    deletedAt: project.deletedAt ?? null,
    visible: false,
    expanded: false,
    isLoadingAreas: false,
    isUpdating: false,
    areas: null,
    selectedAreaId: null,
    isCreatingArea: false,
    creatingAreaIds: [],
    beingDeleted: false,
    boundingBoxDiagonalSize: 0,
    boundingBox: null,
  };
}

export function castProjectInProjectTreeToProject(
  project: ProjectInProjectTree
): ProjectWithAreaCount {
  return {
    ...project,
    deletedAt: project.deletedAt === null ? undefined : project.deletedAt,
    boundingBox: project?.boundingBox !== null ? project.boundingBox : undefined,
  };
}

function safeBooleanListReducer(
  list: boolean[],
  reducer: (a: boolean, b: boolean) => boolean,
  defaultValue: boolean
): boolean {
  if (list.length === 0) return defaultValue;

  return list.reduce(reducer);
}

export function getProjectsOnProjectTree(
  projects: ProjectInProjectTree[] | null | undefined,
  visualizationMode: VisualizationMode,
  visualizationModeConsideringSubItems: boolean,
  searchFilter: string
): ProjectInProjectTree[] {
  if (projects === null || projects === undefined || projects.length === 0) return [];

  let result = [...projects];

  if (visualizationMode === "not_deleted") {
    result = result.filter((project) => {
      if (project.deletedAt === null) return true;

      if (visualizationModeConsideringSubItems) {
        const firstNotDeletedArea = project.areas?.find((area) => area.deletedAt === null);
        return firstNotDeletedArea !== undefined;
      }

      return false;
    });
  } else if (visualizationMode === "deleted") {
    result = result.filter((project) => {
      if (project.deletedAt !== null) return true;

      if (visualizationModeConsideringSubItems) {
        const firstDeletedArea = project.areas?.find((area) => area.deletedAt !== null);
        return firstDeletedArea !== undefined;
      }

      return false;
    });
  }

  if (searchFilter.length > 0) {
    result = result.filter((project) => {
      if (checkTextFilter(searchFilter, project.name)) {
        return true;
      } else {
        const firstFilterArea = project.areas?.find((area) =>
          checkTextFilter(searchFilter, area.name)
        );

        return firstFilterArea !== undefined;
      }
    });
  }

  return result;
}

function checkTextFilter(filter: string, input: string): boolean {
  return input.toLowerCase().includes(filter.toLowerCase());
}

export function getAreasOnProjectTree(
  areas: AreaInProjectTree[] | null | undefined,
  visualizationMode: VisualizationMode,
  searchFilter: string
): AreaInProjectTree[] {
  if (areas === null || areas === undefined || areas.length === 0) return [];

  let result = [...areas];

  if (visualizationMode === "not_deleted") {
    result = result.filter((area) => area.deletedAt === null);
  } else if (visualizationMode === "deleted") {
    result = result.filter((area) => area.deletedAt !== null);
  }

  if (searchFilter.length > 0) {
    result = result.filter((area) => checkTextFilter(searchFilter, area.name));
  }

  return result;
}
