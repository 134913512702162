import React from "react";
import { AreaConfig, IndirectClient } from "biohub-model";

import { Button, CircularProgress, TextField, Grid, makeStyles, Box } from "@material-ui/core";

import { useIntl } from "react-intl";
import Dropdown from "../../../../components/Atomic/Inputs/Dropdown";
import AreaConfigEditor from "../../../../components/Atomic/Inputs/AreaConfigEditor";
import ReleaserConfigEditor from "../../../../components/Atomic/Inputs/ReleaserConfigEditor";
import { ReleaserConfigEditorItem } from "../../../../components/Atomic/Inputs/ReleaserConfigEditor/default";
import { Actions, Fields, SelectItem } from "../styles";
import _ from "lodash";
import { AreaConfigWithoutId } from "../../../../store/actions/projectTreeActions";

export type ConfigurationStageProps = SelectClientProps &
  FillNameProps &
  AreaConfigProps &
  ReleasersConfigurationProps & {
    isValidConfiguration: boolean;
    onFinish: () => void;
    loading: boolean;
  };

type SelectClientProps =
  | {
      showClientSelection: false;
    }
  | {
      showClientSelection: true;
      selectedClient: IndirectClient | undefined;
      emptyClientText: string;
      availableClients: IndirectClient[];
      onSelectClient: (client: IndirectClient | undefined) => void;
    };

type FillNameProps =
  | {
      showNameInsertion: false;
    }
  | {
      showNameInsertion: true;
      name: string;
      nameType: "area" | "project";
      onChangeName: (name: string) => void;
    };

type AreaConfigProps =
  | {
      showAreaConfig: false;
    }
  | {
      showAreaConfig: true;
      areaConfig: AreaConfigWithoutId;
      lockedMode: boolean;
      onChangedAreaConfig: (areaConfig: AreaConfigWithoutId) => void;
      validateAreaConfig: (valid: boolean) => void;
    };

type ReleasersConfigurationProps = {
  releasersConfig: ReleaserConfigEditorItem[];
  onChangedReleasersConfig: (config: ReleaserConfigEditorItem[]) => void;
  validateReleasersConfig: (valid: boolean) => void;
};

export default function (props: ConfigurationStageProps): JSX.Element {
  const intl = useIntl();

  const _newProjectIndirectClientNameLocalized: string = intl.formatMessage({
    id: "map.newProject.indirectClientName",
  });
  const _newProjectNameLocalized: string = intl.formatMessage({ id: "map.newProject.projectName" });
  const _newAreaNameLocalized: string = intl.formatMessage({ id: "map.newArea.areaName" });

  const _actionContinueLocalized: string = intl.formatMessage({
    id: "action.continue",
  });

  const classes = useStyles();

  return (
    <Fields>
      <Grid container spacing={1}>
        {props.showClientSelection && (
          <Grid item xs={12} alignItems="center">
            <SelectItem>
              <b>{_newProjectIndirectClientNameLocalized}: </b>
              <em>
                {props.selectedClient === undefined
                  ? props.emptyClientText
                  : props.selectedClient.name}
              </em>
              <Dropdown<IndirectClient | undefined>
                color="light"
                appearance="img-icon"
                listItems={[undefined, ...props.availableClients].map((client) => ({
                  value: client,
                  text: client !== undefined ? client.name : props.emptyClientText,
                }))}
                onSelect={props.onSelectClient}
              />
            </SelectItem>
          </Grid>
        )}
      </Grid>
      <Grid xs={12}>
        <Box height={8} />
      </Grid>
      {props.showNameInsertion && (
        <>
          <Grid
            item
            xs={12}
            // style={{ maxWidth: 360 }}
          >
            <TextField
              error={props.name.length === 0}
              inputProps={{
                className:
                  props.name.length === 0 ? classes.emptyInputColor : classes.defaultInputColor,
              }}
              size="small"
              label={
                props.nameType === "project" ? _newProjectNameLocalized : _newAreaNameLocalized
              }
              variant="outlined"
              value={props.name}
              onChange={(e) => {
                props.onChangeName(e.target.value);
              }}
            />
          </Grid>
          <Grid xs={12}>
            <Box height={13} />
          </Grid>
        </>
      )}
      {props.showAreaConfig && (
        <>
          <AreaConfigEditor
            gridScheme="default"
            colorScheme="default"
            areaConfig={{
              ...props.areaConfig,
              id: "",
            }}
            disableEdition={
              props.lockedMode
                ? [
                    "flightSpeed",
                    "areaPadding",
                    "flightHeight",
                    "trackWidth",
                    "defaultCurvePercentage",
                    "useHighestPolygonPointElevationDataAsHomePoint",
                    "canUseOutsidePolygonSegmentsInRoute",
                  ]
                : undefined
            }
            onChange={(propName, value) => {
              props.onChangedAreaConfig({
                ...props.areaConfig,
                [propName]: value,
              });
            }}
            validateValue={props.validateAreaConfig}
            usingOnlineElevation={props.areaConfig.mustConsiderRelief}
          >
            <ReleaserConfigEditor
              releaserConfigurations={props.releasersConfig}
              onConfigurationChanged={props.onChangedReleasersConfig}
              pathParameters={{
                ...props.areaConfig,
                id: "",
              }}
              gridScheme="default"
              colorScheme="default"
              validateValue={props.validateReleasersConfig}
            />
          </AreaConfigEditor>
        </>
      )}

      <Grid xs={12}>
        <Box height={15} />
      </Grid>

      <Actions>
        <Button
          variant="contained"
          color="primary"
          disabled={!props.isValidConfiguration}
          onClick={props.onFinish}
          size="small"
        >
          {props.loading ? <CircularProgress /> : _actionContinueLocalized}
        </Button>
      </Actions>
    </Fields>
  );
}

const useStyles = makeStyles({
  divider: {
    margin: 0.2,
    backgroundColor: "#EF7622",
  },
  emptyInputColor: {
    color: "#fb0a0a !important",
  },
  defaultInputColor: {
    color: "black !important",
  },
});
