import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import history from "./history";
import { IntlProvider, useIntl } from "react-intl";

import Routes from "./Routes";
import { messagesForLocale } from "../localization";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "../store";
import { BiohubLocale } from "../store/reducers/localeReducer";
import { SystemState } from "../store/reducers/systemReducer";
import HttpsRedirect from "./HttpsRedirect";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useLoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { loadProfileInformation } from "../store/actions/profileActions";
import { fetchCollections } from "../store/actions/collectionsActions";
import { whenCloseApp } from "../store/actions/systemActions";
import { CircularProgress } from "@material-ui/core";
import {
  clearData,
  getCacheDataService,
  instantiateCacheDataService,
} from "../services/Persistence/CacheDataService";
import Constants from "../Constants";
import { KEEP_DATA_PERSIST, LOG_IN_SUCCESS } from "../store/actions/loginActions";
import logo from "../assets/logo/logo_blue.svg";
import Image from "../components/Atomic/BasicComponents/Image";

const _LIBRARIES: Libraries = ["places"];

// Theme with our standard colors.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1d4f90",
    },
    secondary: {
      main: "#ef7622",
    },
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '&::-webkit-scrollbar': {
  //         width: '5px',
  //         height: '5px',
  //       },
  //       '&::-webkit-scrollbar-thumb': {
  //         background: 'var(--dashboard-hr)',
  //         borderRadius: '10px',
  //       },
  //       '&::-webkit-scrollbar-track': {
  //         background: 'transparent',
  //         borderRadius: '10px',
  //         boxShadow: 'inset 5px 10px 12px var(--gray)',
  //       }
  //     }
  //   }
  // }
});

/**
 * Items in this declaration:
 *
 * Provider - react-redux's store provider, injects the store for all components.
 * IntlProvider - Internationalization provider.
 * Router - Declares all navigations and decides which page to render at any given time.
 */
function Root(): JSX.Element {
  return (
    <HttpsRedirect>
      <Provider store={store}>
        <PersistedRoot />
      </Provider>
    </HttpsRedirect>
  );
}

function PersistedRoot(): JSX.Element {
  const dispatch = useDispatch();

  const localeCode = useSelector((state: SystemState) => state.locale.localeCode);
  const localeChanged = useSelector((state: SystemState) => state.locale.changed);
  const keepLoggedIn = useSelector((state: SystemState) => state.login.keepLoggedIn);

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      if (history.length > 1 && !keepLoggedIn && history.location.pathname !== "/login") {
        dispatch(whenCloseApp());
      }
    });
  }, []);

  return (
    <FutureBuilder
      function={async () => {
        await instantiateCacheDataService({
          appVersion: Constants.APP_VERSION,
        });

        await delay(1000);

        const cacheDataService = getCacheDataService()!;

        const loginInfo = await cacheDataService.retrieveLoginInfo();

        if (loginInfo === undefined) {
          await clearData();
          return;
        }

        /// Put back in the state the login variables
        dispatch({
          type: LOG_IN_SUCCESS,
          payload: {
            userToken: loginInfo.token,
            userId: loginInfo.userId,
          },
        });

        /// Put back in the state the keep logged in variable
        dispatch({
          type: KEEP_DATA_PERSIST,
          payload: {
            status: loginInfo.keepLoggedIn,
          },
        });

        await delay(100);

        dispatch(
          loadProfileInformation(loginInfo.userId, localeCode, localeChanged, () =>
            dispatch(fetchCollections())
          )
        );
      }}
    >
      <ThemeProvider theme={theme}>
        <LocalizedRoot />
      </ThemeProvider>
    </FutureBuilder>
  );
}

function delay(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

const FutureBuilder = (props: {
  function: () => Promise<void>;
  children: JSX.Element;
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await props.function();

      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          height: window.innerHeight,
          width: window.innerWidth,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            msTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image src={logo} appearance="img-divulgation-banner" />
          <CircularProgress
            style={{
              marginTop: -50,
            }}
          />
        </div>
      </div>
    );
  }

  return props.children;
};

/**
 * For use in Root only. Gets the locale from the store to pass it to IntlProvider.
 */
function LocalizedRoot(): JSX.Element {
  const locale: BiohubLocale = useSelector((state: SystemState) => state.locale.localeCode);

  return (
    <IntlProvider messages={messagesForLocale(locale)} locale={locale} defaultLocale="en">
      <Router history={history}>
        <Routes />
      </Router>
    </IntlProvider>
  );
}

export default Root;
