import { createMuiTheme, MenuItem, ThemeProvider } from "@material-ui/core";
import React, { useState } from "react";
import {
  AreaInProjectTree,
  ProjectInProjectTree,
  VisualizationMode,
} from "../../store/reducers/projectTreeReducer";
import { TextField } from "../Atomic/Inputs/Field/styles";
import SearchImage from "../../assets/icon/icon_search_thick_without_circle.svg";
import PlusIcon from "../../assets/icon/icon_orange_plus.svg";
import { Tune } from "@mui/icons-material";

import { useIntl } from "react-intl";
import ListProjectsInProjectTree from "./ListProjectsInProjectTree";
import CustomDropdown from "../Atomic/Inputs/DropdownOptions/default";
import { useDispatch, useSelector } from "react-redux";
import {
  setVisualizationMode,
  setVisualizationModeConsideringSubItems,
} from "../../store/actions/projectTreeActions";
import { SystemState } from "../../store/reducers/systemReducer";
import Select from "../Atomic/Inputs/Select";
import Checkbox from "../Atomic/Inputs/Checkbox";

type Props = {
  onClickToCreateProject: () => void;
  onClickToEditProjectSettings: (project: ProjectInProjectTree) => void;
  onClickToAddAreaInProject: (projectId: ProjectInProjectTree) => void;
  onClickToDeleteProject: (project: ProjectInProjectTree) => void;
  onClickToRestoreProject: (project: ProjectInProjectTree) => void;
  onClickToEditAreaSettings: (area: AreaInProjectTree) => void;
  onClickToDeleteArea: (area: AreaInProjectTree) => void;
  onClickToRestoreArea: (area: AreaInProjectTree) => void;
  onClickDuplicateArea: (area: AreaInProjectTree) => void;
  onClickCopyArea: (area: AreaInProjectTree) => void;
  onClickPasteCopiedArea: (project: ProjectInProjectTree) => void;
  height: number;
};

function ProjectOverlay(props: Props): JSX.Element {
  const [filter, setFilter] = useState("");
  const [openedSettings, setOpenedSettings] = useState<boolean>(false);

  /// For handling the search label's shrink property.
  const [searchFocus, setSearchFocus] = useState<boolean>(false);

  const intl = useIntl();
  const selectVisualizationModeHeight = 30;
  const settingsPaddingTop = 10;
  let settingsPaddingBottom = 20;

  const dispatch = useDispatch();

  const visualizationMode = useSelector(
    (state: SystemState) => state.projectTree.visualizationMode
  );
  const visualizationModeConsideringSubItems = useSelector(
    (state: SystemState) => state.projectTree.visualizationModeConsideringSubItems
  );

  const visualizationModes: VisualizationMode[] = ["not_deleted", "deleted", "all"];

  const visualizationModeToText = (mode: VisualizationMode) => {
    switch (mode) {
      case "not_deleted":
        return intl.formatMessage({ id: "map.projectTree.visualizationMode.notDeleted" });
      case "deleted":
        return intl.formatMessage({ id: "map.projectTree.visualizationMode.deleted" });
      case "all":
        return intl.formatMessage({ id: "map.projectTree.visualizationMode.all" });
    }
  };

  let listProjectsSubtractHeight = 0;
  if (openedSettings) {
    listProjectsSubtractHeight =
      selectVisualizationModeHeight + settingsPaddingBottom + settingsPaddingTop;
    if (visualizationMode !== "all") {
      listProjectsSubtractHeight = listProjectsSubtractHeight + 27;
      settingsPaddingBottom = 5;
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 12,
          paddingLeft: 10,
          paddingBottom: 8,
          paddingRight: 12,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ThemeProvider theme={theme}>
          <TextField
            label={
              filter.length > 0
                ? ""
                : `${searchFocus ? "" : "         "}${intl.formatMessage({
                    id: "placeholder.search",
                  })}`
            }
            onFocus={(_) => {
              setSearchFocus(true);
            }}
            onBlur={(_) => {
              setSearchFocus(false);
            }}
            InputLabelProps={{ shrink: searchFocus }}
            color="secondary"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            variant="outlined"
            size="small"
            style={{
              height: 35,
              width: 248,
            }}
            /* labelVerticalPadding={12} */
            InputProps={{
              startAdornment: (
                <img
                  src={SearchImage}
                  style={{ height: 18, width: 18 }}
                  alt={"searchfield-start-adornment"}
                />
              ),
            }}
            inputProps={{
              style: { color: "black" },
            }}
          />
        </ThemeProvider>
        <div
          onClick={() => setOpenedSettings(!openedSettings)}
          style={{
            cursor: "pointer",
            paddingLeft: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tune style={{ height: "25px", width: "25px" }} />
        </div>
        <div
          onClick={(_) => {
            props.onClickToCreateProject();
          }}
          style={{
            cursor: "pointer",
            paddingLeft: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={PlusIcon}
            style={{ height: "25px", width: "25px" }}
            alt={"add-project-plus-icon"}
          />
        </div>
      </div>
      {openedSettings && (
        <div
          style={{
            paddingBottom: settingsPaddingBottom,
            paddingTop: settingsPaddingTop,
            display: "flex",
            flexDirection: "column",
            paddingLeft: 20,
          }}
        >
          <Select
            key="project-tree-visualization-mode-dropdown"
            width={300}
            height={selectVisualizationModeHeight}
            initialId={visualizationMode}
            options={visualizationModes.map((mode) => ({
              id: mode,
              title: visualizationModeToText(mode),
            }))}
            onChange={(mode) => {
              if (mode === null) return;

              dispatch(setVisualizationMode(mode as VisualizationMode));
            }}
            placeholder={intl.formatMessage({ id: "map.projectTree.visualizationMode" })}
          />

          {visualizationMode !== "all" && (
            <Checkbox
              label={intl.formatMessage({
                id: "map.projectTree.visualizationModeConsideringSubItems",
              })}
              color="light"
              initialState={visualizationModeConsideringSubItems}
              onClick={() => {
                dispatch(
                  setVisualizationModeConsideringSubItems(!visualizationModeConsideringSubItems)
                );
              }}
            />
          )}
        </div>
      )}

      {/* List of projects */}
      <ListProjectsInProjectTree
        height={props.height - listProjectsSubtractHeight}
        onClickToAddAreaInProject={props.onClickToAddAreaInProject}
        onClickToEditAreaSettings={props.onClickToEditAreaSettings}
        onClickToEditProjectSettings={props.onClickToEditProjectSettings}
        onClickToDeleteArea={props.onClickToDeleteArea}
        onClickToRestoreArea={props.onClickToRestoreArea}
        onClickToDeleteProject={props.onClickToDeleteProject}
        onClickToRestoreProject={props.onClickToRestoreProject}
        onClickDuplicateArea={props.onClickDuplicateArea}
        onClickCopyArea={props.onClickCopyArea}
        onClickPasteCopiedArea={props.onClickPasteCopiedArea}
        searchFilter={filter}
      />
    </>
  );
}

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#ef7622",
    },
    text: {
      primary: "black",
      hint: "var(--dashboard-secondary)",
      secondary: "var(--dashboard-secondary)",
    },
  },
});

function compareProjectOverlayProps(prevProps: Props, newProps: Props): boolean {
  if (prevProps.height !== newProps.height) return false;
  return true;
}

export default React.memo(ProjectOverlay, compareProjectOverlayProps);
