import React from "react";
import HybridTileLayer from "../components/hybrid_tile_layer";
import SatelliteTileLayer from "../components/satellite_tile_layer";
import RoadMapTileLayer from "../components/road_map_tile_layer";
import { BaseMapTypeId } from "../../BaseMap";

export default (props: { mapType: BaseMapTypeId; showingMapLabels: boolean }): JSX.Element => {
  return (
    <>
      {props.mapType === "satellite" && props.showingMapLabels && <HybridTileLayer />}
      {props.mapType === "satellite" && !props.showingMapLabels && <SatelliteTileLayer />}
      {props.mapType === "roadmap" && <RoadMapTileLayer />}
    </>
  );
};
