import { Flight, FlightReport, Location, Role, UnitSystem } from "biohub-model";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import BaseMap from "../../../components/map/BaseMap";
import BaseMapController from "../../../components/map/BaseMapController";
import { boundingBoxForAreas } from "../../../core/geometricFunctions";
import { SystemState } from "../../../store/reducers/systemReducer";
import { MapReport } from "../styles";
import FlightFromProps from "../../../components/map/impl/map_layers/flight_from_props";
import TileLayerFromProps from "../../../components/map/impl/map_layers/tile_layer_from_props";

interface Props {
  dataReport: FlightReport[0];
  flightToPlot: Flight;
  mode: "plan" | "flight";
}

export default (props: Props) => {
  const [mapController, setMapController] = useState<BaseMapController | null>(null);

  const intl = useIntl();
  const flightToPlot = props.flightToPlot;

  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const flightEnvironmentSnapshot = flightToPlot.flightEnvironmentSnapshot;

  return (
    <>
      <MapReport>
        <BaseMap
          onInitialized={async (controller) => {
            setMapController(controller);

            await controller.moveToBoundingBox(
              boundingBoxForAreas([flightEnvironmentSnapshot.plannedArea.polygon])!
            );
          }}
          onClick={() => {}}
          onZoomChanged={() => {}}
          onCurrentCenterChanged={() => {}}
          onMapBoundsChanged={() => {}}
          onMapDigitalSizeChanged={() => {}}
          getChildren={(map) => (
            <>
              <TileLayerFromProps mapType="satellite" showingMapLabels={false} />
              <FlightFromProps flight={flightToPlot} mode={props.mode} mapRotation={0} map={map} />
            </>
          )}
        />
      </MapReport>
    </>
  );
};
